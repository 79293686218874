import * as React from 'react'

import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import { AutocompleteContainer } from '../components'

export const defaultDate = () => {
  const date = new Date()
  date.setHours(0, 0, 0, 0)

  return date
}

type DateRangeFieldsProps = {
  setStartDate: (date: Date) => void
  setEndDate: (date: Date) => void
}

export const DateRangeFields = ({
  setStartDate,
  setEndDate,
}: DateRangeFieldsProps) => {
  const [timer, setTimer] = React.useState<NodeJS.Timeout>()
  const [startInput, setStartInput] = React.useState<Date>(defaultDate())
  const [endInput, setEndInput] = React.useState<Date>(defaultDate())

  const afterStartInputUpdate = (newValue: Date) => {
    setStartDate(newValue)

    if (endInput < newValue) {
      setEndInput(newValue)
      setEndDate(newValue)
    }
  }

  const afterEndInputUpdate = (newValue: Date) => {
    setEndDate(newValue)

    if (newValue < startInput) {
      setStartInput(newValue)
      setStartDate(newValue)
    }
  }

  return (
    <React.Fragment>
      <AutocompleteContainer>
        <DatePicker
          disableFuture
          value={startInput}
          inputFormat='yyyy/MM/dd'
          onChange={(newValue, keyboardInputValue) => {
            if (!newValue || isNaN(newValue.getTime())) {
              return
            }

            newValue.setHours(0, 0, 0, 0)
            setStartInput(newValue)

            if (keyboardInputValue) {
              clearTimeout(timer)
              setTimer(setTimeout(() => afterStartInputUpdate(newValue), 1000))
            } else {
              afterStartInputUpdate(newValue)
            }
          }}
          renderInput={(props) => (
            <TextField
              {...props}
              label='Filtrar por fecha inicial'
              fullWidth
            />
          )}
        />
      </AutocompleteContainer>
      <AutocompleteContainer>
        <DatePicker
          disableFuture
          value={endInput}
          inputFormat='yyyy/MM/dd'
          onChange={(newValue, keyboardInputValue) => {
            if (!newValue || isNaN(newValue.getTime())) {
              return
            }

            newValue.setHours(0, 0, 0, 0)
            setEndInput(newValue)

            if (keyboardInputValue) {
              clearTimeout(timer)
              setTimer(setTimeout(() => afterEndInputUpdate(newValue), 1000))
            } else {
              afterEndInputUpdate(newValue)
            }
          }}
          renderInput={(props) => (
            <TextField
              {...props}
              label='Filtrar por fecha final'
              fullWidth
            />
          )}
        />
      </AutocompleteContainer>
    </React.Fragment>
  )
}
