import * as React from 'react'

import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'

import { IdentityProofsStep } from './identity_proofs_step'
import { LegalAddressStep } from './legal_address_step'
import { LivenessProofStep } from './liveness_proof_step'
import { PendingRevisionStep } from './pending_revision_step'
import { PersonIdentityStep } from './person_identity_step'
import { ButtonContainer, ButtonsContainer, Dialog, ResponsiveStepper } from '../../components'
import { UPDATE_VERIFICATION_MUTATION } from '../../queries'

import type { UpdateVerificationData, UpdateVerificationVars } from '../../queries'

const STEP_LABELS = [
  'Información básica',
  'Dirección',
  'Documento de identificación',
  'Reconocimiento facial',
]

type OnboardingBasicProps = {
  dialogTitle?: string
  dialogContentText?: React.ReactNode
}

export const OnboardingBasic = ({
  dialogTitle,
  dialogContentText,
}: OnboardingBasicProps) => {
  const [activeStep, setActiveStep] = React.useState(0)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  const [updateVerification] =
    useMutation<UpdateVerificationData, UpdateVerificationVars>(UPDATE_VERIFICATION_MUTATION, {
      errorPolicy: 'all',
    })

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const isOnConfirmationStep = (activeStep === STEP_LABELS.length)

  React.useEffect(() => {
    if (isOnConfirmationStep) {
      updateVerification()
    }
  }, [isOnConfirmationStep, updateVerification])

  return (
    <React.Fragment>
      <ResponsiveStepper
        activeStep={activeStep}
        stepLabels={STEP_LABELS}
      >
        {(activeStep) => {
          switch (activeStep) {
          case 0: return (
            <PersonIdentityStep
              handleNext={handleNext}
            />
          )
          case 1: return (
            <LegalAddressStep
              handleBack={handleBack}
              handleNext={handleNext}
            />
          )
          case 2: return (
            <IdentityProofsStep
              handleBack={handleBack}
              handleNext={handleNext}
            />
          )
          case 3: return (
            <LivenessProofStep
              handleBack={handleBack}
              handleNext={dialogTitle ? openDialog : handleNext}
            />
          )
          }
        }}
      </ResponsiveStepper>
      {(dialogTitle) && (
        <Dialog
          open={dialogOpen}
          onClose={closeDialog}
          maxWidth='xs'
          title={dialogTitle}
          contentText={dialogContentText}
          contentTextProps={{ textAlign: 'justify' }}
        >
          <ButtonsContainer>
            <ButtonContainer xs={6}>
              <Button
                fullWidth
                color='primary'
                variant='outlined'
                onClick={closeDialog}
              >
                Regresar
              </Button>
            </ButtonContainer>
            <ButtonContainer xs={6}>
              <Button
                fullWidth
                color='primary'
                variant='contained'
                onClick={() => {
                  closeDialog()
                  handleNext()
                }}
              >
                Confirmar
              </Button>
            </ButtonContainer>
          </ButtonsContainer>
        </Dialog>
      )}
      {(isOnConfirmationStep) && (
        <PendingRevisionStep />
      )}
    </React.Fragment>
  )
}
