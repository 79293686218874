import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Box, Collapse } from '@mui/material'
import { TransitionGroup } from 'react-transition-group'

import { Banner } from './banner'
import { BannerButton } from './button'
import { AppContext } from '../../contexts'
import {
  ACCOUNT_INFORMATION_QUERY,
  getRenewalBannerMessage,
  getRenewalHref,
} from '../../queries'

import type { BannerProps } from './banner'
import type { AccountInformationData, AccountInformationVars } from '../../queries'

type PseudoBannerProps = Omit<BannerProps, 'closeBanner'> & {
  closable?: boolean
}

export const BannersDisplay = () => {
  const { appName } = React.useContext(AppContext)

  const { loading, data } =
    useQuery<AccountInformationData, AccountInformationVars>(ACCOUNT_INFORMATION_QUERY)

  const [banners, setBanners] = React.useState<PseudoBannerProps[]>([])

  const addBanner = (banner: PseudoBannerProps) => {
    setBanners((prev) => [...prev, banner])
  }

  const closeBanner = (id: string) => {
    setBanners((prev) => [...prev.filter((i) => i.id !== id)])
  }

  React.useEffect(() => {
    if (loading) {
      setBanners([])
      return
    }

    switch (data?.accountInformation.accountStatus) {
    case 'IDENTITY_PENDING_REVIEW': return addBanner({
      id: 'IDENTITY_PENDING_REVIEW',
      body: 'Estamos verificando tus datos de identificación',
    })
    case 'IDENTITY_PENDING_CHANGES': return addBanner({
      id: 'IDENTITY_PENDING_CHANGES',
      body: 'Necesitamos que corrijas tus datos de identificación',
      color: 'warning',
      action: (
        <BannerButton
          color='warning'
          href='/onboarding/'
        >
          Corregir datos
        </BannerButton>
      ),
    })
    case 'IDENTITY_PENDING_MORE_CHANGES': return addBanner({
      id: 'IDENTITY_PENDING_MORE_CHANGES',
      body: 'Necesitamos algunos datos adicionales',
      color: 'warning',
      action: (
        <BannerButton
          color='warning'
          href='/enhanced_onboarding/'
        >
          Completar datos
        </BannerButton>
      ),
    })
    case 'IDENTITY_REJECTED': return addBanner({
      id: 'IDENTITY_REJECTED',
      body: 'Tus datos de identificación fueron rechazados (te enviamos un mail con los detalles)',
      color: 'error',
    })
    case 'IDENTITY_UNVERIFIED': return addBanner({
      id: 'IDENTITY_UNVERIFIED',
      body: `Para garantizar tu seguridad al usar ${appName} debemos verificar tu identidad`,
      color: 'warning',
      action: (
        <BannerButton
          color='warning'
          href='/onboarding/'
        >
          Completar perfil
        </BannerButton>
      ),
    })
    case 'EMAIL_UNCONFIRMED': return addBanner({
      id: 'EMAIL_UNCONFIRMED',
      body: 'Antes de validar tu identidad debes confirmar tu email con el correo que te enviamos',
    })
    }

    data?.accountInformation.pendingKycRenewals.forEach((renewal) => (
      addBanner({
        id: `${renewal.type}_${renewal.resourceId}`,
        body: getRenewalBannerMessage(renewal),
        color: 'warning',
        action: (
          <BannerButton
            color='warning'
            href={getRenewalHref(renewal)}
          >
            Actualizar
          </BannerButton>
        ),
      })
    ))
  }, [loading, data, appName])

  return (
    <Box
      sx={(theme) => ({
        mb: banners.length > 0 ? 2 : 0,
        transition: theme.transitions.create('margin-bottom'),
      })}
    >
      <TransitionGroup>
        {banners.map((banner) => (
          <Collapse key={banner.id}>
            <Banner
              {...banner}
              closeBanner={banner.closable ? closeBanner : undefined}
            />
          </Collapse>
        ))}
      </TransitionGroup>
    </Box>
  )
}
